export const RESET = "editor/RESET";
export const EVENT_LOADED = "editor/EVENT_LOADED";
export const EVENT_LOAD_FAILED = "editor/EVENT_LOAD_FAILED";
export const EVENT_SAVING = "editor/EVENT_SAVING";
export const MOVE_TO_QUEUE_WARNING = "editor/MOVE_TO_QUEUE_WARNING";
export const MOVE_TO_QUEUE_CANCELED = "editor/MOVE_TO_QUEUE_CANCELED";
export const EDIT_CONFLICT = "editor/EDIT_CONFLICT";
export const EDIT_CONFLICT_DISMISSED = "editor/EDIT_CONFLICT_DISMISSED";
export const EVENT_SLUG_CHECKING = "editor/EVENT_CHECKING";
export const EVENT_SLUG_CHECKED = "editor/EVENT_SLUG_CHECKED";
export const CATEGORIES_LOADED = "editor/CATEGORIES_LOADED";
export const EDIT_SIGNUP = "editor/EDIT_SIGNUP";
export const EDIT_NEW_SIGNUP = "editor/EDIT_NEW_SIGNUP";
export const SAVED_SIGNUP = "editor/SAVED_SIGNUP";
export const SIGNUP_EDIT_CANCELED = "editor/SIGNUP_EDIT_CANCELED";
